import React from "react"
import SEO from "../components/seo"
import { GlobalStyle } from "../components/GlobalStyles"
import styled from "styled-components"
import ueLogo from '../images/ue-logo.png';
import gormanetLogo  from '../images/gormanet-logo.png'



const StyledWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  width: 100%;
  @media(max-width: 450px) {
   
  }
`
const StyledTitle = styled.h1`
width: 100%;
text-align: center;
font-size: 32px;
font-weight: bold;
margin-bottom: 50px;
`

const StyledSubtitle = styled.h3`
width: 90%;
max-width: 1024px;
font-size: 20px;
font-weight: bold;
margin-bottom: 5px;
`

const StyledUELogo = styled.img`
  height: 60px;
  width: auto;
  margin: 50px auto 25px;
  @media(max-width: 450px) {
   width: 100%;
   height: auto;
  }
`

const StyledParagraph = styled.p`
width: 90%;
max-width: 1024px;
margin: 0 0 10px;
text-align: left;
margin-bottom: 25px;
font-size: 20px;
line-height: 1.5;

`
const StyledBold = styled.span`
font-weight: bold;
`

const StyledDevopsbay = styled.a`
  height: 200px;
  width: 200px;
  background-image: url(${gormanetLogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  
`

const StyledWrapUE = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  @media(max-width: 450px) {
  margin: 0 10px;
  }
`

const PostureScan = () => (
  <>
    <GlobalStyle />
    <SEO
      title="Inteligentny rozwój"
      description=""
    />
    <StyledWrap>
    <StyledDevopsbay href="/"  rel="noopener noreferrer" />


          <StyledTitle>Inteligentny rozwój</StyledTitle>
          <StyledParagraph>Gormanet spółka z ograniczoną odpowiedzialnością w konsorcjum z Instytutem Biotechnologii i Medycyny Molekularnej i Lab Control spółka z ograniczona odpowiedzialnością realizuje projekt dofinansowany z Funduszy Europejskich pt. „PostureScan – system oparty o uczenie maszynowe służący do analizy, interpretacji wad postawy u dzieci i młodzieży”</StyledParagraph>
          <StyledSubtitle>Cel projektu</StyledSubtitle>
          <StyledParagraph>Celem projektu „PostureScan” jest opracowanie technologii i metodologii opartej o metody Uczenia Maszynowego, pozwalające przedstawicielom zawodów medycznych: pielęgniarkom, fizjoterapeutom, lekarzom na sprawną i efektywną ocenę postawy u dzieci, wyszukiwanie wad postawy oraz deformacji na wczesnym ich etapie, co pozwoli wprowadzić nową jakość w zapobieganiu następstwom wad postawy w życiu dorosłym lub narastaniu deformacji. System ograniczy do minimum ewentualne pomyłki podczas oceny wizualnej i pozwoli na wczesne wykrywanie potencjalnych wad postawy, oraz jej obserwację w czasie. Pozwoli na śledzenie remisji lub nasilenia zmian, co połączone z dostosowaną do tego pracą rehabillitacyjną umożliwi stworzenie powszechnego systemu oraz nowego standardu opieki nad dziećmi z wadami postawy. “Posture Scan” umożliwi dokładniejsze i szybsze przeprowadzania badań przesiewowych w przedszkolach, szkołach podstawowych i szkołach średnich co pozwoli na zmniejszenie kosztów badania i umożliwi dokładniejszą analizę i bardziej spersonalizowane sugestie dotyczące rehabilitacji wybranego schorzenia.</StyledParagraph>
          <StyledSubtitle>Planowane efekty</StyledSubtitle>
          <StyledParagraph>Rezultatem projektu będzie stworzenie metodologii badania i technologii opartej o techniki Uczenia Maszynowego w formie systemu i metodologii badania, która usprawni proces definiowania przesłanek o wadach postawy i deformacjach u dzieci i młodzieży. System zostanie wsparty pomiarami z pedobarografu, stereoskopią, lidografią i rollupem zaprojektowanym do wsparcia procesu wyznaczania superpixeli. Posture Scan będzie narzędziem dedykowanym przedstawicielom zawodów medycznych tj. pielęgniarkom, fizjoterapeutom, ortopedom i lekarzom rodzinnym, zajmującym się badaniem wad postawy ciała podczas badań indywidualnych i badań przesiewowych. Posture Scan umożliwi szybką analizę i precyzyjną ocenę postawy u badanego pacjenta. Poza elementem badania stanu obecnego pacjenta badanego Posture Scan będzie tworzyło tzw. Historię postawy pacjenta badanego, w której widoczny będzie progres lub regres wybranej patologii postawy ciała co znacząco przyczyni się do dobierania odpowiedniego postępowania mającego na celu leczenie i zapobieganie dalszym schorzeniom. Posture Scan jako narzędzie, którego głównym przeznaczeniem będzie analizowanie skoliozy i wad postawy u młodych osób (dzieci między 3 a 18) będzie spełniało funkcję prewencyjną we wczesnym wykrywaniu wad postawy u badanych osób, jednocześnie minimalizując potencjalne ryzyko powikłań. Opracowana metodologia pracy z młodym pacjentem pozwoli stworzyć nowy standard opieki nad wykrywaniem i obserwacją wad postawy w czasie, co skutkować będzie obniżonymi kosztami opieki w życiu dorosłym i polepszeniem standardu życia. Wykorzystanie technologii będzie atrakcyjne dla podmiotów realizujących programy profilaktyki wad postawy w kraju i na świecie. Infrastruktura technologii ze względu na swoją formę, ma potencjał wsparcia diagnostyki wad postawy w krajach i regionach słabiej rozwiniętych.</StyledParagraph>
          <StyledParagraph><StyledBold>Wartość projektu:</StyledBold> 8 482 620,72 zł</StyledParagraph>
          <StyledParagraph><StyledBold>Wkład funduszy europejskich:</StyledBold> 7 217 653,06 zł</StyledParagraph>
          <StyledWrapUE>
      <StyledUELogo src={ueLogo}></StyledUELogo>
    </StyledWrapUE>
    </StyledWrap>

  </>
)

export default PostureScan
